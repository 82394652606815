import { PartnerFileType } from '@models/partner-file-type.enum';
import { ROUTERS } from './routers.const';

export const PARTNER_FILES_TITLE = {
  [PartnerFileType.DOCUMENTS]: 'PARTNER_DOCUMENTS',
  [PartnerFileType.MEDIA_KIT]: 'PARTNER_MEDIAKIT',
};

export const PARTNER_FILES_LINK = {
  [PartnerFileType.DOCUMENTS]: ROUTERS.portalDocuments,
  [PartnerFileType.MEDIA_KIT]: ROUTERS.portalMediaKit,
};
