import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { tokenHeaderSetter } from '@utils/token-header-setter';
import { type DataMultiple } from '@models/data-multiple.interface';
import { type LegalDocument } from '@models/legal-documents.interface';

export const partnerFilesApi = createApi({
  reducerPath: 'partnerFilesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: tokenHeaderSetter,
  }),
  endpoints: builder => ({
    getPartnerFiles: builder.query<DataMultiple<LegalDocument>, string>({
      query: () => `partner-documents?populate=files.content`,
    }),
  }),
});

export const { useGetPartnerFilesQuery } = partnerFilesApi;
