// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.router-under-construction {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/router-under-construction/RouterUnderConstruction.scss"],"names":[],"mappings":"AAIA;EACE,YAAA;AAHF","sourcesContent":["@import '@styles/colors.scss';\n@import '@styles/constants.scss';\n\n\n.router-under-construction {\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
