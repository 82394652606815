import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { tokenHeaderSetter } from '@utils/token-header-setter';
import { type DataMultiple } from '@models/data-multiple.interface';
import { type BlogArticle } from '@models/blog-articles.interface';
import { upperFirst } from 'lodash';

export const blogArticlesApi = createApi({
  reducerPath: 'blogArticlesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: tokenHeaderSetter,
  }),
  endpoints: builder => ({
    getBlogArticles: builder.query<
      DataMultiple<BlogArticle>,
      { query: string; categoryId: number | null; onlyForPartners: boolean }
    >({
      query: ({ categoryId, query, onlyForPartners }) =>
        `blog-articles?populate=*${query ? `&filters[$and][0][$or][0][title][$containsi]=${query}&filters[$and][0][$or][1][title][$containsi]=${upperFirst(query)}` : ''}${categoryId ? `&filters[blog_category][id][$eq]=${categoryId}` : ''}&filters[$and][1][$or][2][onlyForPartner]=${onlyForPartners.toString()}${!onlyForPartners ? '&filters[$and][1][$or][3][onlyForPartner][$null]=true' : ''}`,
    }),
  }),
});

export const { useGetBlogArticlesQuery } = blogArticlesApi;
