// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.partner-articles {
  padding: 24px;
}
.partner-articles__breadcrumbs {
  padding: 24px;
  background-color: #FFFFFF;
  border-left: 1px solid #E6E8EB;
  gap: 16px;
  font-weight: 600;
}
.partner-articles__breadcrumbs-text {
  color: #838E9B;
}`, "",{"version":3,"sources":["webpack://./src/containers/partner-articles/PartnerArticles.scss","webpack://./src/styles/colors.scss","webpack://./src/styles/constants.scss"],"names":[],"mappings":"AAIA;EACE,aAAA;AAHF;AAKE;EACE,aAAA;EACA,yBCOI;EDNJ,8BAAA;EACA,SAAA;EACA,gBERe;AFKnB;AAKI;EACE,cCIM;ADPZ","sourcesContent":["@import '@styles/colors.scss';\n@import '@styles/constants.scss';\n\n\n.partner-articles {\n  padding: 24px;\n\n  &__breadcrumbs {\n    padding: 24px;\n    background-color: $white;\n    border-left: 1px solid $gray-light;\n    gap: 16px;\n    font-weight: $bold-font-weight;\n\n    &-text {\n      color: $gray-hard;\n    }\n  }\n}","$blue-hard: #1E49F5;\n$blue-medium: #5374FF;\n$blue-light: #BDCAFF;\n$blue-1: #591DDB;\n$heading: #000026;\n$body-text: #495366;\n$caption: #7E8696;\n$border-line: #D8DEEB;\n$background: #F6F9FF;\n$action-success: #00D17F;\n$action-warning: #FFB526;\n$action-red: #FF005C;\n$primary-blue: #712FFF;\n$primary-hover: #8A5AF6;\n$primary-light: #F8F4FF;\n$background-1: #F3F4F5;\n$white: #FFFFFF;\n$black: #2C2A32;\n$black-1: #5B5964;\n$gray-hard: #838E9B;\n$gray-medium: #B5BBC3;\n$gray-light: #E6E8EB;\n$yellow-light: #FFF0E0;\n$yellow-hard: #FFB526;\n$red-light: #FFE9F1;\n$red-hard: #EF0056;\n$green-light: #E6F7F1;\n$green-hard: #00B37D;","$gap: 24px;\n$gap-large: 32px;\n$padding: 64px;\n$padding-large: 100px;\n$bold-font-weight: 600;\n$font-weight: 400;\n$border-radius: 20px;\n$border-radius-medium: 30px;\n$border-radius-big: 40px;\n$break-point: 1439px;\n$mobile-break-point: 1023px;\n$mobile-navbar-height: 65px;\n$small-navbar-height: 95px;\n$navbar-height: 111px;\n$title-font-size: 32px;\n$mobile-title-font-size: 24px;\n$mobile-subtitle-font-size: 18px;\n$title-font-size-big: 42px;\n$box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);\n$subtitle-font-size-big: 18px;\n$subtitle-font-size: 16px;\n$text-font-size: 14px;\n$content-width: 1200px;\n$max-width: 1920px;\n$icon-font-size: 24px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
