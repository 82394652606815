import React, { useEffect, useState } from 'react';
import './PartnerAuthModal.scss';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Modal } from 'antd';
import { type PartnerData } from '@models/partner-data.interface';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ROUTERS } from '@constants/routers.const';
import { HOME_FOOTER_ID, PARTNER_FORM } from '@constants/layout.constants';
import { scrollToElement } from '@utils/scroll-to-element';
import { useGetUserRolesMutation, useSendPartnerAuthFormMutation } from '@api/partner-auth';
import { type Data } from '@models/data.interface';

export default function PartnerAuthModal({
  modalOpen,
  setModalOpen,
}: {
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
}): JSX.Element {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [sendPartnerAuthForm, { isError, isLoading: loginIsLoading, data: loginData, isSuccess: loginIsSuccess }] =
    useSendPartnerAuthFormMutation();
  const [getUserRoles, { isLoading: rolesIsLoading, isSuccess: rolesIsSuccess, data: rolesData }] =
    useGetUserRolesMutation();
  const [isPermissionError, setIsPermissionError] = useState(false);

  const validateMessages = {
    required: t('GENERAL.VALIDATION.REQUIRED'),
  };

  const onFinish = (values: PartnerData): void => {
    void sendPartnerAuthForm(values);
  };

  useEffect(() => {
    if (loginIsSuccess) {
      const currentData = loginData as Data<{ token: string }>;
      void getUserRoles(currentData.data.token);
    }
  }, [loginIsSuccess]);

  useEffect(() => {
    if (rolesIsSuccess) {
      const currentRolesData = rolesData as Data<{ roles: Array<{ name: string }> }>;

      if (currentRolesData.data.roles.some(role => role.name === 'Partner')) {
        const currentLoginData = loginData as Data<{ token: string }>;

        localStorage.setItem('portal_token', currentLoginData.data.token);
        setIsPermissionError(false);
        navigate(ROUTERS.portalDocuments);
        setModalOpen(false);
      } else {
        setIsPermissionError(true);
      }
    }
  }, [rolesIsSuccess]);

  return (
    <Modal
      open={modalOpen}
      onCancel={() => {
        setModalOpen(false);
      }}
      centered
      footer={null}
      className='partner-modal'
      width={692}>
      <div className='partner-modal__body align-center flex flex-column'>
        <div className='flex flex-column align-center partner-modal__header'>
          <img src='/images/logo-zakroma.png' className='partner-modal__img' alt='logo' />
          <div className='flex flex-column align-center partner-modal__text'>
            <h1 className='partner-modal__title'>{t('CONTAINERS.PARTNER_AUTH.TITLE')}</h1>
            <p className='partner-modal__description'>{t('CONTAINERS.PARTNER_AUTH.DESCRIPTION')}</p>
          </div>
        </div>
        <Form
          className='flex flex-column partner-modal__form'
          name='partner-form'
          onFinish={onFinish}
          validateMessages={validateMessages}
          form={form}
          layout='vertical'>
          <div className='flex flex-column partner-modal__inputs'>
            {(isError || isPermissionError) && (
              <div className='partner-modal__error'>
                {t(`CONTAINERS.PARTNER_AUTH.${isPermissionError ? 'PERMISSION_ERROR' : 'ERROR'}`)}
              </div>
            )}
            <Form.Item<PartnerData>
              name='email'
              required={false}
              rules={[{ required: true }]}
              label={t('CONTAINERS.PARTNER_AUTH.LOGIN.LABEL')}>
              <Input className='partner-modal__input' placeholder={t('CONTAINERS.PARTNER_AUTH.LOGIN.PLACEHOLDER')} />
            </Form.Item>
            <Form.Item<PartnerData>
              name='password'
              required={false}
              rules={[{ required: true }]}
              label={t('CONTAINERS.PARTNER_AUTH.PASSWORD.LABEL')}>
              <Input.Password
                className='partner-modal__input'
                placeholder={t('CONTAINERS.PARTNER_AUTH.PASSWORD.PLACEHOLDER')}
              />
            </Form.Item>
          </div>
          <div className='partner-modal__footer flex flex-column align-center'>
            <Button
              type='primary'
              htmlType='submit'
              className='partner-modal__button'
              loading={loginIsLoading || rolesIsLoading}>
              {t('CONTAINERS.PARTNER_AUTH.SUBMIT')}
            </Button>
            <Link
              onClick={() => {
                setModalOpen(false);
                if (pathname.includes(ROUTERS.partners)) {
                  scrollToElement(HOME_FOOTER_ID);
                }
              }}
              to={`${ROUTERS.partners}?${PARTNER_FORM}`}>
              {t('COMPONENT.NAVBAR.START_PARTNER')}
            </Link>
          </div>
        </Form>
      </div>
    </Modal>
  );
}
