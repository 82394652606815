import React, { useMemo } from 'react';
import './PartnerProgram.scss';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Col, Row } from 'antd';
import { type LegalFile } from '@models/legal-documents.interface';
import { ROUTERS } from '@constants/routers.const';
import IconByName from '@components/icon-by-name/IconByName';
import { useGetPartnerFilesQuery } from '@api/partner-files';

export default function PartnerProgram(): JSX.Element {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data } = useGetPartnerFilesQuery('');

  const files = useMemo(() => {
    return data
      ? data.data.reduce((acc: LegalFile[], cur) => {
          return [
            ...acc,
            ...cur.attributes.files.map(file => {
              return { ...file, fileName: cur.attributes.title };
            }),
          ];
        }, [])
      : [];
  }, [data]);

  const currentFile = useMemo(() => {
    return id && !!files.length ? files.find(file => file.content.data?.attributes.hash === id) : null;
  }, [id, files]);

  return id ? (
    <>
      {currentFile && (
        <div className='flex flex-column' style={{ height: '100%' }}>
          <div className='flex align-center partner-program__breadcrumbs'>
            <Link to={ROUTERS.portalProgram}>{t('CONTAINERS.PARTNER_CHAPTER.PARTNER_PROGRAM')}</Link>
            <img src='/images/icons/arrow-right.svg' alt='arrow-icon' />
            <div className='partner-program__breadcrumbs-text'>{currentFile.fileName}</div>
          </div>
          <iframe
            style={{ height: '100%' }}
            src={`${process.env.REACT_APP_IMAGE_URL ?? ''}${currentFile.content.data?.attributes.url ?? ''}`}
            allow='autoplay; fullscreen'
          />
        </div>
      )}
    </>
  ) : (
    <div className='flex flex-column partner-program'>
      <div className='flex flex-column wiki-welcome-block'>
        <div className='wiki-welcome-block__title'>{t('CONTAINERS.PARTNER_CHAPTER.PARTNER_PROGRAM')}</div>
      </div>
      <Row gutter={[32, 32]}>
        {files.map(file => {
          return (
            <Col span={12} key={file.id}>
              <Link
                className='flex align-center space-between partner-program__item'
                to={`${ROUTERS.portalProgram}/${file?.content.data?.attributes.hash ?? ``}`}>
                <div className='flex align-center partner-program__item-text'>
                  <IconByName type='FileTextOutlined' />
                  <div className='partner-program__item-title'>{file.fileName}</div>
                </div>
                <IconByName type='DownloadOutlined' />
              </Link>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
