import { type PartnerData } from '@models/partner-data.interface';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const partnerAuthApi = createApi({
  reducerPath: 'partnerAuthApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_ADMIN_URL,
  }),
  endpoints: builder => ({
    sendPartnerAuthForm: builder.mutation<unknown, PartnerData>({
      query: (data: PartnerData) => ({
        url: 'login',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
    }),
    getUserRoles: builder.mutation<unknown, string>({
      query: (token: string) => ({
        url: 'users/me',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
  }),
});

export const { useSendPartnerAuthFormMutation, useGetUserRolesMutation } = partnerAuthApi;
