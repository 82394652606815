import React, { useEffect, useState } from 'react';
import './PartnerChapter.scss';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ROUTERS } from '@constants/routers.const';
import { Layout, Menu, Tooltip, type MenuProps } from 'antd';
import { Content } from 'antd/es/layout/layout';
import Sider from 'antd/es/layout/Sider';
import IconByName from '@components/icon-by-name/IconByName';

const PARTNER_PROGRAM_MENU = [
  {
    key: 'program',
    label: 'PARTNER_PROGRAM',
    link: ROUTERS.portalProgram,
    icon: 'IdcardOutlined',
  },
  {
    key: 'articles',
    label: 'PARTNER_ARTICLES',
    link: ROUTERS.portalArticles,
    icon: 'ReconciliationOutlined',
  },
  {
    key: 'documents',
    label: 'PARTNER_DOCUMENTS',
    link: ROUTERS.portalDocuments,
    icon: 'FileTextOutlined',
  },
  {
    key: 'media-kit',
    label: 'PARTNER_MEDIAKIT',
    link: ROUTERS.portalMediaKit,
    icon: 'LayoutOutlined',
  },
];

export default function PartnerChapter(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [selectedKey, setSelectedkey] = useState<string>('');

  const getMenuItems = (): MenuProps['items'] => {
    return PARTNER_PROGRAM_MENU.map(({ key, label, link, icon }) => {
      return {
        label: (
          <Link to={link}>
            <div className='flex align-center partner-chapter__sider-item'>
              <IconByName type={icon} />
              {t(`CONTAINERS.PARTNER_CHAPTER.${label}`)}
            </div>
          </Link>
        ),
        key,
      };
    });
  };

  useEffect(() => {
    const keys = pathname.split('/');
    setSelectedkey(keys[keys.length - 1]);
  }, [pathname]);

  useEffect(() => {
    document.title = t('COMPONENT.NAVBAR.PARTNER_CHAPTER');

    if (!localStorage.getItem('portal_token')) {
      navigate({ pathname: ROUTERS.home, search: 'partnerAuthOpen' });
    }
  }, []);

  return (
    <div className='slow'>
      <Layout>
        <div>
          <Sider className='partner-chapter__sider' width={335} theme='light'>
            <div className='flex flex-column space-between partner-chapter__sider-container'>
              <Menu items={getMenuItems()} mode='inline' selectedKeys={[selectedKey]} className='flex flex-column' />
              <div className='flex flex-column align-center partner-chapter__actions'>
                <Link to={ROUTERS.privacyPolicy}>
                  <Tooltip
                    title={t('CONTAINERS.PARTNER_CHAPTER.USER_AGREEMENT')}
                    arrow={false}
                    placement='topRight'
                    overlayClassName='partner-chapter__tooltip'>
                    <img src='/images/icons/user-agreement.svg' alt='user-agreement' />
                  </Tooltip>
                </Link>
                <div
                  className='partner-chapter__login'
                  onClick={() => {
                    localStorage.removeItem('portal_token');
                    navigate({ pathname: ROUTERS.home, search: 'partnerAuthOpen' });
                  }}>
                  <IconByName type='LoginOutlined' />
                </div>
              </div>
            </div>
          </Sider>
        </div>
        <Content>
          <Outlet />
        </Content>
      </Layout>
    </div>
  );
}
