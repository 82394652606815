import React, { useMemo } from 'react';
import './PartnerFiles.scss';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Col, Row } from 'antd';
import { type LegalDocument, type LegalFile } from '@models/legal-documents.interface';
import { PartnerFileType } from '@models/partner-file-type.enum';
import { PARTNER_FILES_LINK, PARTNER_FILES_TITLE } from '@constants/partner.const';
import PartnerChapterBreadcrumbs from '@components/partner-chapter-breadcrumbs/PartnerChapterBreadcrumbs';
import { type DataMultiple } from '@models/data-multiple.interface';
import PartnerFilesItem from '@components/partner-files-item/PartnerFilesItem';

export default function PartnerFiles({
  type,
  data,
}: {
  type: PartnerFileType;
  data?: DataMultiple<LegalDocument>;
}): JSX.Element {
  const { t } = useTranslation();
  const { id } = useParams();

  const getFileItem = (file: LegalFile): Record<PartnerFileType, JSX.Element> => {
    return {
      [PartnerFileType.DOCUMENTS]: (
        <Link
          className='flex align-center space-between partner-program__item'
          to={`${PARTNER_FILES_LINK[type]}/${file?.content.data?.attributes.hash ?? ``}`}>
          <PartnerFilesItem file={file} />
        </Link>
      ),
      [PartnerFileType.MEDIA_KIT]: (
        <a
          className='flex align-center space-between partner-program__item'
          download
          href={`${process.env.REACT_APP_IMAGE_URL ?? ''}${file?.content.data?.attributes.url ?? ''}`}>
          <PartnerFilesItem file={file} />
        </a>
      ),
    };
  };

  const files = useMemo(() => {
    return data
      ? data.data.reduce((acc: LegalFile[], cur) => {
          return [
            ...acc,
            ...cur.attributes.files.map(file => {
              return { ...file, fileName: cur.attributes.title };
            }),
          ];
        }, [])
      : [];
  }, [data]);

  const currentFile = useMemo(() => {
    return id && !!files.length ? files.find(file => file.content.data?.attributes.hash === id) : null;
  }, [id, files]);

  return id ? (
    <>
      {currentFile && (
        <div className='flex flex-column h-100'>
          <PartnerChapterBreadcrumbs
            currentRoutText={currentFile.fileName}
            mainLink={PARTNER_FILES_LINK[type]}
            mainLinkText={t('CONTAINERS.PARTNER_CHAPTER.' + PARTNER_FILES_TITLE[type])}
          />
          <iframe
            className='h-100'
            src={`${process.env.REACT_APP_IMAGE_URL ?? ''}${currentFile.content.data?.attributes.url ?? ''}`}
            allow='autoplay; fullscreen'
          />
        </div>
      )}
    </>
  ) : (
    <div className='flex flex-column partner-program'>
      <div className='flex flex-column wiki-welcome-block'>
        <div className='wiki-welcome-block__title'>{t('CONTAINERS.PARTNER_CHAPTER.' + PARTNER_FILES_TITLE[type])}</div>
      </div>
      <Row gutter={[32, 32]}>
        {files.map(file => {
          return (
            <Col span={12} key={file.id}>
              {getFileItem(file)[type]}
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
