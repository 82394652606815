import React from 'react';
import { type LegalFile } from '@models/legal-documents.interface';
import IconByName from '@components/icon-by-name/IconByName';

export default function PartnerFilesItem({ file }: { file: LegalFile }): JSX.Element {
  return (
    <>
      <div className='flex align-center partner-program__item-text'>
        <IconByName type='FileTextOutlined' />
        <div className='partner-program__item-title'>{file.fileName}</div>
      </div>
      <IconByName type='DownloadOutlined' />
    </>
  );
}
