import { articleApi } from '@api/article';
import { articlesApi } from '@api/articles';
import { wikiSectionsApi } from '@api/wiki-sektions';
import { blogArticlesApi } from '@api/blog-articles';
import { blogArticleApi } from '@api/blog-article';
import { blogPageApi } from '@api/blog-page';
import { blogCategoriesApi } from '@api/blog-categories';
import { pagesApi } from '@api/pages';
import { contactApi } from '@api/contacts';
import { blogSubscriptionApi } from '@api/blog-subscription';
import { legalFilesApi } from '@api/legal-files';
import { materialsPageApi } from '@api/materials-page';
import { productsApi } from '@api/product';
import { partnerAuthApi } from '@api/partner-auth';
import { partnerFilesApi } from '@api/partner-files';

export const createReducerArgs = (): any => {
  const rootReducer = {
    [articleApi.reducerPath]: articleApi.reducer,
    [articlesApi.reducerPath]: articlesApi.reducer,
    [wikiSectionsApi.reducerPath]: wikiSectionsApi.reducer,
    [blogArticlesApi.reducerPath]: blogArticlesApi.reducer,
    [blogArticleApi.reducerPath]: blogArticleApi.reducer,
    [blogPageApi.reducerPath]: blogPageApi.reducer,
    [blogCategoriesApi.reducerPath]: blogCategoriesApi.reducer,
    [pagesApi.reducerPath]: pagesApi.reducer,
    [contactApi.reducerPath]: contactApi.reducer,
    [blogSubscriptionApi.reducerPath]: blogSubscriptionApi.reducer,
    [legalFilesApi.reducerPath]: legalFilesApi.reducer,
    [materialsPageApi.reducerPath]: materialsPageApi.reducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [partnerAuthApi.reducerPath]: partnerAuthApi.reducer,
    [partnerFilesApi.reducerPath]: partnerFilesApi.reducer,
  };
  return rootReducer;
};
