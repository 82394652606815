import React from 'react';
import { useGetPartnerFilesQuery } from '@api/partner-files';
import { PartnerFileType } from '@models/partner-file-type.enum';
import PartnerFiles from '@components/partner-files/PartnerFiles';

export default function PartnerDocuments(): JSX.Element {
  const { data } = useGetPartnerFilesQuery('');

  return <PartnerFiles type={PartnerFileType.DOCUMENTS} data={data} />;
}
