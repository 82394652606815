import React from 'react';
import { Link } from 'react-router-dom';

export default function PartnerChapterBreadcrumbs({
  mainLink,
  mainLinkText,
  currentRoutText,
}: {
  mainLink: string;
  currentRoutText: string;
  mainLinkText: string;
}): JSX.Element {
  return (
    <div className='flex align-center partner-program__breadcrumbs'>
      <Link to={mainLink}>{mainLinkText}</Link>
      <img src='/images/icons/arrow-right.svg' alt='arrow-icon' />
      <div className='partner-program__breadcrumbs-text'>{currentRoutText}</div>
    </div>
  );
}
