import React, { useEffect } from 'react';
import './KnowledgeBase.scss';
import { useTranslation } from 'react-i18next';
import WikiWelcomeBlock from '@components/wiki-welcome-block/WikiWelcomeBlock';
import { Col, Layout, Row } from 'antd';
import { Content } from 'antd/es/layout/layout';
import ProductsListCart from '@components/products-list-cart/ProductsListCart';
import { useGetProductsQuery } from '@api/product';

function KnowledgeBase(): JSX.Element {
  const { t } = useTranslation();

  const { data } = useGetProductsQuery('');

  useEffect(() => {
    document.title = t('COMPONENT.NAVBAR.DOCS');
  }, []);

  return (
    <div className='slow knowledge-base'>
      <Layout>
        <Content>
          <div className='flex flex-column knowledge-base__content'>
            <WikiWelcomeBlock />
            {data && (
              <Row gutter={[32, 32]}>
                {data.data.map(product => (
                  <Col span={12} key={product.attributes.slug}>
                    <ProductsListCart product={product.attributes} />
                  </Col>
                ))}
              </Row>
            )}
          </div>
        </Content>
      </Layout>
    </div>
  );
}

export default KnowledgeBase;
