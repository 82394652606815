import React from 'react';
import './BlogArticlesList.scss';
import { type BlogArticle } from '@models/blog-articles.interface';
import { processUrlString } from '@utils/produse-url-string';
import { ROUTERS } from '@constants/routers.const';
import { Link } from 'react-router-dom';

function BlogArticlesList({ articles, isPartner }: { articles: BlogArticle[]; isPartner?: boolean }): JSX.Element {
  const sortArticles = [...articles].sort(
    (a, b) => +new Date(b.attributes.publishedAt) - +new Date(a.attributes.publishedAt),
  );

  return (
    <div className='flex flex-wrap articles'>
      {sortArticles.map(article => {
        const { title, description, blog_category: category, cover, publishedAt, slug } = article.attributes;
        const imgUrl = `${process.env.REACT_APP_IMAGE_URL ?? ''}${cover?.data?.attributes?.url ?? ''}`;
        return (
          <Link
            to={processUrlString(isPartner ? ROUTERS.portalArticle : ROUTERS.blogArticle, { id: slug })}
            className='articles__cart flex flex-column space-between'
            key={slug}>
            <div className='flex flex-column articles__header'>
              <div className='articles__image-container'>
                <div className='articles__image' style={{ backgroundImage: `url(${imgUrl})` }} />
              </div>
              <div className='articles__text flex flex-column'>
                <div className='articles__title'>{title}</div>
                <div className='articles__description'>{description}</div>
              </div>
            </div>
            <div className='flex space-between align-center'>
              {category.data ? <div className='articles__category'>{category.data.attributes.name}</div> : <div></div>}
              <div className='articles__date'>{new Date(publishedAt).toLocaleDateString()}</div>
            </div>
          </Link>
        );
      })}
    </div>
  );
}

export default BlogArticlesList;
