import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { tokenHeaderSetter } from '@utils/token-header-setter';
import { type ProductsData } from '@models/product.interface';

export const productsApi = createApi({
  reducerPath: 'productsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: tokenHeaderSetter,
  }),
  endpoints: builder => ({
    getProducts: builder.query<ProductsData, string>({
      query: () => 'products?populate=*',
    }),
  }),
});

export const { useGetProductsQuery } = productsApi;
