import { Empty } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './RouterUnderConstruction.scss';

export default function RouterUnderConstruction(): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className='flex align-center center router-under-construction'>
      <Empty description={t('CONTAINERS.ROUTER_UNDER_CONSTRUCTION.DESCRIPTION')} />
    </div>
  );
}
