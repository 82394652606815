import React from 'react';
import { ARTICLE_PREFIX, ROUTERS, WIKI_PREFIX } from '@constants/routers.const';
import { createBrowserRouter, redirect } from 'react-router-dom';
import MainPage from '@containers/main/Main';
import Home from '@containers/home/Home';
import KnowledgeBaseProduct from '@containers/knowledge-base-product/KnowledgeBaseProduct';
import Blog from '@containers/blog/Blog';
import Contacts from '@containers/contacts/Contacts';
import ZakromaStorage from '@containers/zakroma-storage/ZakromaStorage';
import ZakromaArchive from '@containers/zakroma-archive/ZakromaArchive';
import BlogArticle from '@containers/blog-article/BlogArticle';
import Clients from '@containers/clients/Clients';
import Partners from '@containers/partners/Partners';
import RouterErrorPage from '@containers/router-error-page/RouterErrorPage';
import Legal from '@containers/legal/Legal';
import KnowledgeBase from '@containers/knowledge-base/KnowledgeBase';
import { PRODUCT } from '@constants/products.const';
import Search from '@containers/search/Search';
import WikiArticles from '@containers/wiki-articles/WikiArticles';
import Article from '@containers/article/Article';
import Materials from '@containers/materials/Materials';
import PartnerChapter from '@containers/partner-chapter/PartnerChapter';
import PartnerProgram from '@containers/partner-program/PartnerProgram';
import RouterUnderConstruction from '@components/router-under-construction/RouterUnderConstruction';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <MainPage />,
    children: [
      {
        path: ROUTERS.home,
        element: <Home />,
      },
      {
        path: ROUTERS.blog,
        element: <Blog />,
      },
      { path: ROUTERS.blogArticle, element: <BlogArticle /> },
      {
        path: ROUTERS.contacts,
        element: <Contacts />,
      },
      {
        path: ROUTERS.knowledgeBase,
        element: <KnowledgeBase />,
      },
      ...Object.values(PRODUCT).map(p => {
        return {
          path: ROUTERS.product(p),
          element: <KnowledgeBaseProduct />,
          children: [
            {
              path: ROUTERS.search(p),
              element: <Search />,
            },
            {
              path: ROUTERS.articles(p),
              element: <WikiArticles />,
            },
            {
              path: ROUTERS.article(p),
              element: <Article />,
            },
          ],
        };
      }),

      {
        path: ROUTERS.storage,
        element: <ZakromaStorage />,
      },
      {
        path: ROUTERS.archive,
        element: <ZakromaArchive />,
      },
      {
        path: ROUTERS.clients,
        element: <Clients />,
      },
      {
        path: ROUTERS.partners,
        element: <Partners />,
      },
      {
        path: ROUTERS.legal,
        element: <Legal />,
        children: [
          {
            path: ROUTERS.legalFile,
          },
        ],
      },
      { path: ROUTERS.materials, element: <Materials /> },
      {
        element: <PartnerChapter />,
        children: [
          { path: ROUTERS.portalProgram, element: <PartnerProgram />, children: [{ path: ROUTERS.portalProgramFile }] },
          { path: ROUTERS.portalArticles, element: <RouterUnderConstruction /> },
          { path: ROUTERS.portalDocuments, element: <RouterUnderConstruction /> },
          { path: ROUTERS.portalMediaKit, element: <RouterUnderConstruction /> },
          { path: ROUTERS.privacyPolicy, element: <RouterUnderConstruction /> },
        ],
      },
    ],
  },
  {
    path: ROUTERS.articleOld,
    loader: ({ params }) => {
      return redirect(`${ARTICLE_PREFIX(PRODUCT.STORAGE)}${encodeURI(params.id ?? '')}`);
    },
  },
  {
    path: ROUTERS.articlesOld,
    loader: ({ params }) => {
      return redirect(`${WIKI_PREFIX(PRODUCT.STORAGE)}${encodeURI(params.id ?? '')}`);
    },
  },
  {
    path: ROUTERS.searchOld,
    loader: ({ request }) => {
      const query = request.url.split('?search=')[1];
      return redirect(`${ROUTERS.search(PRODUCT.STORAGE)}${query ? `?search=${query}` : ''}`);
    },
  },
  {
    path: '*',
    element: <MainPage />,
    children: [
      {
        path: '*',
        element: <RouterErrorPage />,
      },
    ],
  },
]);
