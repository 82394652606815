export const SMALL_NAVBAR_HEIGHT = 94;
export const PRIMARY_COLOR = '#712FFF';
export const PRIMARY_BACKGROUND = '#F8F4FF';
export const SECONDARY_BACKGROUND = '#F3F4F5';
export const HOME_FOOTER_ID = 'home-footer';
export const PARTNER_FORM = 'partner-form';
export const FOOTNOTES_LABEL_ID = 'footnote-label';
export enum VIEW {
  PC = 'PC',
  TABLET = 'TABLET',
  MOBILE = 'MOBILE',
}
export enum BREAK_POINT {
  PC = 1439,
  TABLET = 1023,
  MOBILE = 374,
}
