import ArticlesListCart from '@components/articles-list-cart/ArticlesListCart';
import { type ArticleShort } from '@models/article.interface';
import { Col, Row } from 'antd';
import React from 'react';
import './ArticlesList.scss';

function ArticlesList({ items }: { items: ArticleShort[] }): JSX.Element {
  return (
    <Row gutter={[32, 0]}>
      <Col span={12}>
        <div className='flex flex-column article-list__column'>
          {items
            .filter((_el, index) => index % 2 === 0)
            .map(el => (
              <ArticlesListCart key={el.attributes.slug} article={el} />
            ))}
        </div>
      </Col>
      <Col span={12}>
        <div className='flex flex-column article-list__column'>
          {items
            .filter((_el, index) => index % 2 !== 0)
            .map(el => (
              <ArticlesListCart key={el.attributes.slug} article={el} />
            ))}
        </div>
      </Col>
    </Row>
  );
}

export default ArticlesList;
