import React from 'react';
import './PartnerArticles.scss';
import { useTranslation } from 'react-i18next';
import Blog from '@containers/blog/Blog';
import { useParams } from 'react-router-dom';
import BlogArticle from '@containers/blog-article/BlogArticle';

export default function PartnerArticles(): JSX.Element {
  const { t } = useTranslation();
  const { id } = useParams();

  return id ? (
    <BlogArticle isPartner />
  ) : (
    <div className='flex flex-column partner-articles'>
      <div className='flex flex-column wiki-welcome-block'>
        <div className='wiki-welcome-block__title'>{t('CONTAINERS.PARTNER_CHAPTER.PARTNER_ARTICLES')}</div>
      </div>
      <Blog isPartner />
    </div>
  );
}
