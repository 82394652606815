import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { tokenHeaderSetter } from '@utils/token-header-setter';
import { type DataMultiple } from '@models/data-multiple.interface';
import { type BlogCategory } from '@models/blog-categories.interface';

export const blogCategoriesApi = createApi({
  reducerPath: 'blogCategoriesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: tokenHeaderSetter,
  }),
  endpoints: builder => ({
    getBlogCategories: builder.query<DataMultiple<BlogCategory>, { onlyForPartners: boolean }>({
      query: ({ onlyForPartners }) =>
        `blog-categories?populate=*&filters[$or][0][onlyForPartners][$eq]=${onlyForPartners.toString()}${!onlyForPartners ? '&filters[$or][1][onlyForPartners][$null]=true' : ''}`,
    }),
  }),
});

export const { useGetBlogCategoriesQuery } = blogCategoriesApi;
