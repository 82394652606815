import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { tokenHeaderSetter } from '@utils/token-header-setter';
import { type DataMultiple } from '@models/data-multiple.interface';
import { type LegalDocument } from '@models/legal-documents.interface';

export const mediaKitApi = createApi({
  reducerPath: 'mediaKitApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: tokenHeaderSetter,
  }),
  endpoints: builder => ({
    getMediaKit: builder.query<DataMultiple<LegalDocument>, string>({
      query: () => `media-kits?populate=files.content`,
    }),
  }),
});

export const { useGetMediaKitQuery } = mediaKitApi;
