import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { tokenHeaderSetter } from '@utils/token-header-setter';
import { type Data } from '@models/data.interface';
import { type MaterialsPage } from '@models/materials-page.interface';

export const materialsPageApi = createApi({
  reducerPath: 'materialsPageApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: tokenHeaderSetter,
  }),
  endpoints: builder => ({
    getMaterials: builder.query<Data<MaterialsPage>, string>({
      query: () => `materials-page/?populate=*`,
    }),
  }),
});

export const { useGetMaterialsQuery } = materialsPageApi;
