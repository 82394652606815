import { HOME_FOOTER_ID, PARTNER_FORM } from '@constants/layout.constants';
import { ROUTERS } from '@constants/routers.const';
import { type MenuProps } from 'rc-menu';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { scrollToElement } from './scroll-to-element';

interface MenuItem {
  label: string;
  key: string;
  link?: string;
  children?: MenuItem[];
}

export const NAV_BAR_CONFIG: MenuItem[] = [
  {
    label: 'COMPONENT.NAVBAR.PRODUCTS',
    key: 'products',
    children: [
      {
        label: 'COMPONENT.NAVBAR.STORAGE',
        key: 'storage',
        link: ROUTERS.storage,
      },
      {
        label: 'COMPONENT.NAVBAR.ARCHIVE',
        key: 'archive',
        link: ROUTERS.archive,
      },
    ],
  },
  {
    label: 'COMPONENT.NAVBAR.INFORMATION',
    key: 'info',
    children: [
      {
        label: 'COMPONENT.NAVBAR.CLIENTS',
        key: 'clients',
        link: ROUTERS.clients,
      },
      {
        label: 'COMPONENT.NAVBAR.PARTNERS',
        key: 'partners',
        link: ROUTERS.partners,
      },
    ],
  },
  {
    label: 'COMPONENT.NAVBAR.BLOG',
    key: 'blog',
    link: ROUTERS.blog,
  },
  {
    label: 'COMPONENT.NAVBAR.DOCS',
    key: 'docs',
    link: ROUTERS.knowledgeBase,
  },
  {
    label: 'COMPONENT.NAVBAR.CONTACTS',
    key: 'contacts',
    link: ROUTERS.contacts,
  },
  {
    label: 'COMPONENT.NAVBAR.MATERIALS',
    key: 'materials',
    link: ROUTERS.materials,
  },
  {
    label: 'COMPONENT.NAVBAR.PARTNER_PROGRAM',
    key: 'partner-program',
    children: [
      {
        label: 'COMPONENT.NAVBAR.START_PARTNER',
        key: 'start-partner',
        link: `${ROUTERS.partners}?${PARTNER_FORM}`,
      },
      {
        label: 'COMPONENT.NAVBAR.PARTNER_CHAPTER',
        key: 'partner-chapter',
      },
    ],
  },
];

export const getMenuItems = (isFooter = false, callbackFn?: () => void): MenuProps['items'] => {
  const { t } = useTranslation();
  return NAV_BAR_CONFIG.filter(item =>
    isFooter ? item.key !== 'partner-program' : item.link !== ROUTERS.materials,
  ).map(item => ({
    label: item.link ? <Link to={item.link}> {t(item.label)}</Link> : <div>{t(item.label)}</div>,
    key: item.key,
    children: item.children?.map(child => ({
      label: child.link ? (
        <Link
          onClick={() => {
            if (child.key === 'start-partner') {
              scrollToElement(HOME_FOOTER_ID);
            }
          }}
          to={`${child.link}`}>
          {' '}
          {t(child.label)}
        </Link>
      ) : (
        <div
          onClick={() => {
            if (child.key === 'partner-chapter' && callbackFn) callbackFn();
          }}>
          {t(child.label)}
        </div>
      ),
      key: child.key,
    })),
  }));
};
