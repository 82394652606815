import React from 'react';
import { PartnerFileType } from '@models/partner-file-type.enum';
import PartnerFiles from '@components/partner-files/PartnerFiles';
import { useGetMediaKitQuery } from '@api/partner-mediakit';

export default function PartnerMediaKit(): JSX.Element {
  const { data } = useGetMediaKitQuery('');

  return <PartnerFiles type={PartnerFileType.MEDIA_KIT} data={data} />;
}
