export enum PRODUCT {
  STORAGE = 'storage',
  ARCHIVE = 'archive',
  DISK = 'disk',
}

export const PRODUCTS_WIKI_ID = {
  [PRODUCT.STORAGE]: '4',
  [PRODUCT.ARCHIVE]: '9',
  [PRODUCT.DISK]: '14',
};
