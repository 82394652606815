import React, { useEffect } from 'react';
import './Materials.scss';
import { useTranslation } from 'react-i18next';
import { useViewMode } from '@hooks/use-window-size';
import Footer from '@components/footer/Footer';
import { VIEW } from '@constants/layout.constants';
import { useGetMaterialsQuery } from '@api/materials-page';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkSmartypants from 'remark-smartypants';
import rehypeRaw from 'rehype-raw';

export default function Materials(): JSX.Element {
  const { t } = useTranslation();
  const view = useViewMode();

  const { data } = useGetMaterialsQuery('');

  useEffect(() => {
    document.title = t('COMPONENT.NAVBAR.MATERIALS');
  }, []);

  return (
    <div className='flex flex-column materials--area'>
      <div className={`materials flex flex-column ${view === VIEW.PC ? 'materials__content' : ''}`}>
        <h1 className='materials__title cell flex-0'>{t('CONTAINERS.MATERIALS.TITLE')}</h1>
        {data && (
          <ReactMarkdown
            className='materials__list'
            remarkPlugins={[remarkGfm, remarkSmartypants]}
            rehypePlugins={[rehypeRaw]}
            components={{
              a: ({ ...props }) => {
                return <a {...props} target='_blank' />;
              },
            }}>
            {data.data.attributes.content}
          </ReactMarkdown>
        )}
      </div>

      <div className='materials__footer'>
        <Footer view={view} />
      </div>
    </div>
  );
}
