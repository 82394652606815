import React, { useEffect, useState } from 'react';
import './NavBar.scss';
import { Menu, Divider, Button } from 'antd';
import type { MenuProps } from 'antd';
import { ROUTERS } from '@constants/routers.const';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import Icon from '@ant-design/icons';
import { getMenuItems } from '@utils/get-menu-items';
import { useViewMode } from '@hooks/use-window-size';
import { VIEW } from '@constants/layout.constants';
import { ReactComponent as MenuIcon } from '@icons/menu.svg';
import { ReactComponent as CloseMenuIcon } from '@icons/close.svg';
import MobileNavigationMenu from '@components/mobile-navigation-menu/MobileNavigationMenu';
import ReadUsButton from '@components/read-us-button/ReadUsButton';
import PartnerAuthModal from '@components/partner-auth-modal/PartnerAuthModal';

function NavBar({ isMobileViewAvailiable }: { isMobileViewAvailiable: boolean }): JSX.Element {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const goToPortal = (): void => {
    if (localStorage.getItem('portal_token')) navigate(ROUTERS.portalProgram);
    else {
      setModalOpen(true);
    }
  };

  const { pathname } = useLocation();
  const [current, setCurrent] = useState('');
  const [menuItems] = useState<MenuProps['items']>(getMenuItems(false, goToPortal));
  const [modalOpen, setModalOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const view = useViewMode();
  const handleLogoClick = (): void => {
    navigate(ROUTERS.home);
  };

  useEffect(() => {
    setMenuActive();
  }, [pathname]);

  useEffect(() => {
    const needOpenPartnerAuth = searchParams.get('partnerAuthOpen') != null;
    if (needOpenPartnerAuth) {
      setModalOpen(true);
      searchParams.delete('partnerAuthOpen');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const setMenuActive = (): void => {
    if (pathname.includes(ROUTERS.knowledgeBase)) {
      setCurrent('docs');
      return;
    }

    if (pathname.includes(ROUTERS.blog)) {
      setCurrent('blog');
      return;
    }

    if (pathname.includes(ROUTERS.portal)) {
      setCurrent('partner-chapter');
      return;
    }

    const keys = pathname.split('/');
    setCurrent(keys[keys.length - 1]);
  };

  return (
    <div className={`nav-bar ${mobileMenuOpen ? 'nav-bar--open' : ''}`}>
      <div className='nav-bar__content flex space-between align-center '>
        <div className='cell flex-0'>
          <img
            onClick={handleLogoClick}
            src={`/images/${mobileMenuOpen ? 'white-logo.svg' : 'logo.svg'}`}
            alt='logo'
            className='nav-bar__logo'
          />
        </div>
        {!(view === VIEW.MOBILE && isMobileViewAvailiable) ? (
          <>
            <Menu mode={'horizontal'} items={menuItems} className='nav-bar__menu' selectedKeys={[current]} />
            <div className='cell flex-0'>
              <ReadUsButton />
            </div>
          </>
        ) : (
          <>
            <Button
              type={mobileMenuOpen ? 'primary' : 'text'}
              className='nav-bar__button'
              onClick={() => {
                setMobileMenuOpen(!mobileMenuOpen);
              }}>
              <Icon className='nav-bar__button-icon' component={mobileMenuOpen ? CloseMenuIcon : MenuIcon} />
            </Button>
            {mobileMenuOpen && (
              <div className='nav-bar__mobile-menu'>
                <MobileNavigationMenu
                  parentCallback={key => {
                    setMobileMenuOpen(!mobileMenuOpen);
                    if (key && key === 'partner-chapter') {
                      goToPortal();
                    }
                  }}
                />
                <div className='nav-bar__read-us'>
                  <ReadUsButton dark={true} />
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <Divider className='nav-bar__divider' />
      <PartnerAuthModal
        modalOpen={modalOpen}
        setModalOpen={value => {
          setModalOpen(value);
          setMenuActive();
        }}
      />
    </div>
  );
}

export default NavBar;
